<template>
  <div class="PersonalInformation" v-if="have">
    <!--v-if="condition"  -->
    <div class="BigBox">
      <div style="width:100%;color:rgba(255, 255, 255, 1);font-size: 4vh;margin-top: 2vh;">个人资料</div>
      <div class="HeadPortrait">
        <img :src="UserInfo.iconPath" style="width: 100%;height: 100%;border-radius:50%;" v-if="ImgTwo">
        <img src="../../../assets/PersonalCenter/HeadPortrait.png" style="width: 100%;height: 100%;" v-if="ImgOne">
      </div>
      <div class="InputBox">
        <div>昵称：<span style="font-size:1.6vh;display: flex;align-items: center;">{{ UserInfo.nickName }}</span></div>
      </div>
      <div class="InputBox">
        <div>手机号：<span style="font-size:1.6vh;display: flex;align-items: center;">{{ UserInfo.mobile }}</span></div>
      </div>
      <div class="InputBox">
        <div>签名：<span style="font-size:1.6vh;height:100%;display: flex;align-items: center;">{{ UserInfo.endorse }}</span>
        </div>
      </div>
      <div class="ModifyButton" @click="compile()">编辑</div>
    </div>
  </div>
  <!-- 编辑页面 -->
  <div class="PersonalInformation" v-if="HaveOne">
    <!--  -->
    <div class="BigBox">
      <div style="width:100%;color:rgba(255, 255, 255, 1);font-size: 4vh;margin-top: 2vh;">个人资料</div>
      <!-- 上传头像 -->
      <div class="HeadPortrait">
        <el-upload class="avatar-uploader" :action="ation"
          :headers="headers" :data="HeadData" :show-file-list="false" accept="image/jpeg,image/png,image/jpg" :on-change="onChangeUpload"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <img src="../../../assets/PersonalCenter/HeadPortrait.png" style="width: 100%;height: 100%;" v-if="img">
        </el-upload>
      </div>

      <div class="ModifyImg">
        点击头像修改
      </div>
      <el-row class="OrdCons">
        <!-- 昵称 -->
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor" style="margin-top:3vh;">
          <div style="width:6.2%;margin-left:1vw;font-size:2vh;">昵称:</div>
          <el-form-item prop="name" style="width:91%;">
            <el-input v-model="ruleForm.name" placeholder="请填写昵称" clearable></el-input>
          </el-form-item>
        </el-form>
        <!-- 密码 -->
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor" style="margin-top:3vh;">
          <div style="width:6.2%;margin-left:1vw;font-size:2vh;;">密码:</div>

          <el-form-item prop="password" style="width:91%;">
            <el-input v-model="ruleForm.password" placeholder="要包含英文宁母,数字,长度为5至16位" clearable show-password></el-input>
          </el-form-item>
        </el-form>
        <!-- 确认密码 -->
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor" style="margin-top:3vh;">
          <div style="width:12.2%;margin-left:1vw;font-size:2vh;;">确认密码:</div>
          <el-form-item prop="passwordOne" style="width:85%;">
            <el-input v-model="ruleForm.passwordOne" placeholder="请再次输入密码" clearable show-password></el-input>
          </el-form-item>
        </el-form>
        <!-- 签名 -->
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor" style="margin-top:3vh;">
          <div style="width:12.2%;margin-left:1vw;font-size:2vh;;">签名:</div>
          <el-form-item prop="signature" style="width:85%;">
            <el-input v-model="ruleForm.signature" clearable></el-input>
          </el-form-item>
        </el-form>
        <!--  -->
        <div class="ModifyButtonOne" @click="riskSubmit()">保存</div>
      </el-row>


    </div>

  </div>
</template>

<script>
import { reduce } from 'lodash'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 头像
      condition: false,
      ImgOne: true,
      ImgTwo: false,
      // 用户信息
      UserInfo: {},
      //其他参数
      avatar: '',
      loading: true,
      //上传的图片
      file: null,
      img: true,
      // 头像
      ation:this.$Schttp +'/tools/fileUpload/imgUpload',
      imageUrl: '',
      headers: {  Authorization: "Bearer " + this.$cookies.get("token")},
      HeadData: {
        useType: 'user',
        imageFile: '',
      },

      ruleForm: {
        // 昵称
        name: '',
        // 密码
        password: '',
        // 确认密码
        passwordOne: '',
        // 签名
        signature: '',
      },
      // 编辑
      have: true,
      HaveOne: false,
      rules: {
        name: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
          // {  max:4,message: '长度在5~16个字符',trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value) == false) {
                callback(new Error("密码格式必须为数字或字母,只能为6~16位!"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          },
        ],
        passwordOne: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],

      },
    }
  },
  created() {
    this.PersonageList()
  },
  mounted() {

  },
  inject: ["fatherMethod"],
  methods: {
    // 点击编辑
    compile() {
      this.have = false;
      this.HaveOne = true
    },

    // 获取个人信息
    PersonageList() {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: this.$Schttp + '/vtp/app/customer/getUserInfo',
        // params:{
        //     GoodsCodeid:this.GoodsCodeid
        // }
        headers: {
                Authorization: "Bearer " + this.$cookies.get("token"),
                "Content-Type": "application/json",
            },
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.UserInfo = res.data.data
            if (this.UserInfo.iconPath != null) {
              this.ImgTwo = true;
              this.ImgOne = false;
            }
          }

        })
        .catch(function (error) {
        });
    },
    // 修改个人信息
    riskSubmit() {
      if (this.imageUrl == '') {
        this.$message({
          message: '头像不可为空',
          type: 'warning'
        });
        return
      }
      if (this.ruleForm.name == '') {
        this.$message({
          message: '昵称不可为空',
          type: 'warning'
        });
        return
      }
      if (this.ruleForm.password == '') {
        this.$message({
          message: '密码不可为空',
          type: 'warning'
        });
        return
      }
      if (this.ruleForm.passwordOne == '') {
        this.$message({
          message: '确认密码不可为空',
          type: 'warning'
        });
        return
      }
      var axios = require('axios');
      var config = {
        method: 'put',
        url: this.$Schttp + '/vtp/app/customer/update',
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
        data: {
          passWord: this.ruleForm.password,
          nickName: this.ruleForm.name,
          endorse: this.ruleForm.signature,
          iconPath: this.imageUrl,
        }
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: '编辑成功',
              type: "success"
            });
            this.have = true;
            this.HaveOne = false;
            this.PersonageList()
            this.fatherMethod();
          }

        })
        .catch(function (error) {
        });
    },
    // 点击上传头像
    // 图片上传
    onChangeUpload(file, fileList) {
      this.HeadData.imageFile = file.raw
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.code == 200) {
        this.$message({
          showClose: true,
          message: '上传成功',
          type: "success"
        });
      } else {
        this.$message({
          showClose: true,
          message: '上传失败',
          type: "error"
        });
      }
      if (this.imageUrl == '') {
        this.img = true
      } else {
        this.img = false
      }
    },
    beforeAvatarUpload(file) {
      //在头像上传之前需要做的判断，如判断文件格式
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isLtSize) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
    }

  }
}
</script>

<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

.PersonalInformation {
  width: 99%;
  height: 99%;
  display: flex;
  justify-content: center;
  align-items: center;

  .BigBox {
    width: 94%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .HeadPortrait {
      width: 8vw;
      height: 16vh;
      border-radius: 50%;
      margin-top: 1vh;
      display: flex;
      justify-content: center;
      align-items: center;


      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        border-radius: 50%;

      }

      .avatar {
        width: 100%;
        height: 100%;
        border-radius:50%;
      }
    }

    .ModifyImg {
      border-radius: 30px;
      padding: 2px 28px;
      margin: 1vh 0;
      background-color: #DDDDDD;
      color: #11073B;
      cursor: pointer;
    }

    .OrdCons {
      width: 70%;
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .PerRor {
        width: 70%;
        height: 7vh;
        background-image: url(../../../assets/PersonalCenter/longBorder.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;

        //使用 /deep/
        .el-input /deep/ .el-input__inner {
          width: 100%;
          background-color: rgba(255, 255, 255, 0);
          border: none;
          color: rgba(255, 255, 255, 1);
        }
      }

      .ModifyButtonOne {
        background-image: url(../../../assets/PersonalCenter/Modify.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18%;
        height: 8vh;
        cursor: pointer;
        margin-top: 5vh;
      }
    }

    .InputBox {
      width: 56%;
      height: 7vh;
      display: flex;
      margin: 1vh 0;
      align-items: center;
      background-image: url("../../../assets/PersonalCenter/longBorder.png");
      background-size: 100% 100%;
      color: rgba(221, 221, 221, 1);
      font-size: 16px;
      margin-top: 5vh;

      div {
        margin-left: 3vh;
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
      }
    }

    .ModifyButton {
      background-image: url(../../../assets/PersonalCenter/Modify.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13%;
      height: 8vh;
      cursor: pointer;
      margin-top: 10vh;
    }
  }
}
</style>